import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import VaccinationDetails from './components/VaccinationDetails';
import VaccinationForm from './components/VaccinationForm';
import VaccinationUpdate from './components/VaccinationUpdate';
import VaccinationDelete from './components/VaccinationDelete';

const RoutesChange = () => {
    const isAuthenticated = localStorage.getItem('token');

    return (
        <Router>
            <Switch >
                <Route exact path="/mnnmnmnmnmnmnngfgfgnmngffgfgfnmnnnn" component={isAuthenticated ? Home : Login} />
                <Route path="/jhjhjhlhkhjhkjhkjhjkhkjhjkhhkjkhlogin" component={Login} />
                <Route path="/jhkjhjkhkjhkhjkhjhkhjkhkjhkjregister" component={Register} />
                <Route path="/mnnmnmnmnmnmnngfgfgnmngffgfgfnmnnnnadd-vaccination" component={isAuthenticated ? VaccinationForm : Login} />
                <Route path="/mnnmnmnmnmnmnngfgfgnmngffgfgfnmnnnnvaccination/:id/update" component={isAuthenticated ? VaccinationUpdate : Login} />
                <Route path="/mnnmnmnmnmnmnngfgfgnmngffgfgfnmnnnnvaccination/:id/delete" component={isAuthenticated ? VaccinationDelete : Login} />
                <Route path="/vaccination_detail/:id" component={VaccinationDetails} />
            </Switch>
        </Router>
    );
}

export default RoutesChange;