import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import "../App.css"
import { baseUri } from './api';
function VaccinationList() {
    const [vaccinations, setVaccinations] = useState([]);

    useEffect(() => {
        async function fetchVaccinations() {
            try {
                const response = await axios.get(`${baseUri}/api/vaccinations`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setVaccinations(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchVaccinations();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${baseUri}/api/vaccinations/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            // Remove deleted vaccination from the list
            setVaccinations(vaccinations.filter(vaccination => vaccination.id !== id));
        } catch (error) {
            console.error(error);
        }
    };

    function myFunction() {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }
    return (
        <div>
            <h2>Client List</h2>
            <input type="text" id="myInput" onKeyUp={myFunction} placeholder="Search for names.." title="Type in a name"></input>
            <table id="myTable">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Profile</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {vaccinations.map(vaccination => (
                        <tr key={vaccination.id}>
                            <td>{vaccination.fullName}</td>
                            <td>
                                {vaccination.profile && (
                                    <img src={`${baseUri}/uploads/${vaccination.profile}`} alt={vaccination.fullName} width="50" />
                                )}
                            </td>
                            <td>
                                <a style={{ color: "#ffffff" }} href={`/vaccination_detail/${vaccination.id}`}><button className='vaccineView'>View</button></a>
                                <button className='vaccineDelete' onClick={() => handleDelete(vaccination.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default VaccinationList;
