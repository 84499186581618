import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCode from "react-qr-code";
import govLogo from "./images/gov_logo.png"
import { baseUri } from './api';

function formDateString(dateString){
    const date = new Date(dateString);
    const months =['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const day=date.getDate();
    const month=months[date.getMonth()];
    const year=date.getFullYear();
    return `${day} ${month}, ${year}`;
}

function VaccinationDetails({ match }) {
    const [vaccination, setVaccination] = useState(null);

    useEffect(() => {
        async function fetchVaccination() {
            try {
                const response = await axios.get(`${baseUri}/api/vaccinations/${match.params.id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setVaccination(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchVaccination();
    }, [match.params.id]);

    if (!vaccination) {
        return <div></div>;
    }
    const vaccinationDateOne= formDateString(vaccination.vaccinationDateOne);
    const vaccinationDateTwo= formDateString(vaccination.vaccinationDateTwo);
    const vaccinationDateThree= formDateString(vaccination.vaccinationDateThree)

    return (
        <div>
            <div className="container p-0">
                <div className="print">
                    <button className="btn btn-sm btn-primary hide-on-print " onClick={() => window.print()} id="print">Print
                        Certificate</button>
                </div>
            </div>
            <div className="container page">
                <div className="row Header">
                    <div className="logo">
                        <img width="120" height="80" className="img-responsive"
                            src={govLogo} alt="" />
                    </div>
                    <div className="main-text">
                        <p>Federal Democratic Republic of Nepal</p>
                        <p className="mb-0">Ministry of Health and Population</p>
                        <p className="mb-0"> (MoHP)</p>
                    </div>
                </div>
                <div className="certificate">
                    <p>COVID-19 Vaccination Certificate</p>
                </div>
                <div className="content-wrapper">
                    <div className="profile">
                        <img id="rotater"
                            src={`${baseUri}/uploads/${vaccination.profile}`}
                            alt="" />
                    </div>

                    <div className="main-content">
                        <div className="watermark">
                            <img className="img-responsive watermark-image"
                                src={govLogo} alt="" />
                        </div>
                        <div className="detail-info">
                            <div className="item">
                                <div className="item-detail">

                                    <p><b>Full Name:</b></p>
                                </div>
                                <div className="item-detail">

                                    <p>{vaccination.fullName} </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-detail">

                                    <p><b>Address:</b></p>
                                </div>
                                <div className="item-detail">
                                    <p>
                                        {vaccination.address}  </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-detail">

                                    <p><b>Nationality:</b></p>
                                </div>
                                <div className="item-detail">
                                    <p>{vaccination.nationality}</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-detail">
                                    <p><b>Date of Birth (DOB):</b></p>
                                </div>
                                <div className="item-detail">
                                    <p>{vaccination.dob}</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-detail">

                                    <p><b>Passport No. :</b></p>
                                </div>
                                <div className="item-detail">
                                    <p>{vaccination.passportNo}</p>
                                </div>
                            </div>
                            <div className="item ">
                                <div className="item-detail mb-0">

                                    <p><b>Registration ID:</b></p>
                                </div>
                                <div className="item-detail mb-0">
                                    <p>{vaccination.registrationNo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="item">
                            <div className="item-detail">
                                <h4>Vaccination Details:</h4>
                            </div>
                        </div>
                        <div className="item" style={{ maxWidth: '880px' }}>

                            <div className="item-detail" style={{ width: "100%" }}>
                                <div className="row">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="w-35" scope="col">Name of Vaccine</th>
                                                <th scope="col">Dose</th>
                                                <th scope="col">Vaccination Date</th>
                                                <th scope="col">Batch No.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="row" data-label="Name of Vaccine:">{vaccination.nameOfVaccineOne}</td>
                                                <td data-label="Dose:">{vaccination.doseOne.slice(0, 1)}<sup>{vaccination.doseOne.slice(1)}</sup></td>




                                                <td data-label="Vaccination Date:">
                                                    {vaccinationDateOne}</td>
                                                <td data-label="Batch No.:">
                                                    {vaccination.batchNoOne} </td>
                                            </tr>
                                            {
                                                vaccination.nameOfVaccineThree === "" || vaccination.vaccinationDateThree === "" || vaccination.vaccinationdoseThree === "" || vaccination.batchNoThree === "" ?
                                                    <tr>
                                                        <td scope="row" data-label="Name of Vaccine:">{vaccination.nameOfVaccineTwo}</td>
                                                        <td data-label="Dose:">{vaccination.doseTwo}</td>
                                                        <td data-label="Vaccination Date:">
                                                            {vaccinationDateTwo} </td>
                                                        <td data-label="Batch No.:">
                                                            {vaccination.batchNoTwo} </td>
                                                    </tr> :
                                                    (
                                                        <>
                                                            <tr>
                                                                <td scope="row" data-label="Name of Vaccine:">{vaccination.nameOfVaccineTwo}</td>
                                                                <td data-label="Dose:">{vaccination.doseTwo.slice(0, 1)}<sup>{vaccination.doseTwo.slice(1)}</sup></td>
                                                                <td data-label="Vaccination Date:">
                                                                    {vaccinationDateTwo} </td>
                                                                <td data-label="Batch No.:">
                                                                    {vaccination.batchNoTwo} </td>
                                                            </tr>
                                                            <tr>
                                                                <td scope="row" data-label="Name of Vaccine:">{vaccination.nameOfVaccineThree}</td>
                                                                <td data-label="Dose:">{vaccination.doseThree}</td>
                                                                <td data-label="Vaccination Date:">
                                                                    {vaccinationDateThree} </td>
                                                                <td data-label="Batch No.:">
                                                                    {vaccination.batchNoThree} </td>
                                                            </tr>
                                                        </>
                                                    )

                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="qr">
                        {/* <img src="https://vaccine-moph-gov.com/uploads/content/1691490231.png" style={{ width: '35%' }}
                            float="right" /> */}
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "50%", width: "48%" }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>
                <hr className="footer-hr" />
                <div className="footer">
                    <i>This certificate was generated electronically by the Ministry of Health and Population of Federal
                        Democratic Republic of Nepal. And does not require a stamp or signature.<br />
                        Any unauthorized manipulation of the document will be subject to legal action as per laws of the Federal
                        Democratic Republic of Nepal.</i>
                </div>
            </div>

        </div>
    );
}

export default VaccinationDetails;
