import React from 'react';
import VaccinationList from './VaccinationList';

function Home() {
    return (
        <div>
            <h2 style={{ textAlign: "center" }}>Vaccination Record System</h2>
            <a href={"/mnnmnmnmnmnmnngfgfgnmngffgfgfnmnnnnadd-vaccination"} style={{ color: "#ffffff" }}><button className='vaccineView'>Add Vaccination Record</button></a>
            <VaccinationList />
        </div>
    );
}

export default Home;
