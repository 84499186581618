import React, { useState, useEffect } from 'react';
import axios from 'axios';

function VaccinationUpdate({ match }) {
    const [fullName, setFullName] = useState('');
    // Other vaccination fields...

    useEffect(() => {
        async function fetchVaccination() {
            try {
                const response = await axios.get(`/api/vaccinations/${match.params.id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                const { fullName, /* other fields */ } = response.data;
                setFullName(fullName);
                // Set other fields as needed
            } catch (error) {
                console.error(error);
            }
        }

        fetchVaccination();
    }, [match.params.id]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(
                `/api/vaccinations/${match.params.id}`,
                {
                    fullName: fullName,
                    // Other vaccination fields...
                },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <h2>Update Vaccination Record</h2>
            <form onSubmit={handleUpdate}>
                
                <button type="submit">Update</button>
            </form>
        </div>
    );
}

export default VaccinationUpdate;
