import React, { useState } from 'react';
import axios from 'axios';
import { baseUri, frontUri } from './api';
import "../App.css"
function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseUri}/api/login`, { username, password });
            localStorage.setItem('token', response.data.token);
            window.location.assign(`${frontUri}/mnnmnmnmnmnmnngfgfgnmngffgfgfnmnnnn`)
        } catch (error) {
            alert(("Please Enter Valid Credentials"))
        }
    };

    return (
        <div className='loginFormContainer'>
            <form className='loginForm' onSubmit={handleSubmit}>
                <h2>Login Form</h2>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    className='username'
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    className='password'
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit" className='login'>Login</button>
            </form>
        </div>
    );
}

export default Login;
