import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUri, frontUri } from './api';

function VaccinationDelete({ match }) {

    const handleDelete = async () => {
        try {
            await axios.delete(`${baseUri}/api/vaccinations/${match.params.id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            window.location.assign(`${frontUri}/mnnmnmnmnmnmnngfgfgnmngffgfgfnmnnnn`)
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <h2>Delete Vaccination Record</h2>
            <p>Are you sure you want to delete this record?</p>
            <button onClick={handleDelete}>Delete</button>
        </div>
    );
}

export default VaccinationDelete;
