import React, { useState } from 'react';
import axios from 'axios';
import "../App.css"
import { baseUri, frontUri } from './api';
function VaccinationForm() {
    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');
    const [nationality, setNationality] = useState('Nepal');
    const [dob, setdob] = useState('');
    const [passportNo, setpassportNo] = useState('');
    const [registrationNo, setregistrationNo] = useState('2724-230625-50212P');
    const [nameOfVaccineOne, setnameOfVaccineOne] = useState("Janssen (Johnson & Johnson)");
    const [nameOfVaccineTwo, setnameOfVaccineTwo] = useState("Janssen (Johnson & Johnson)");
    const [nameOfVaccineThree, setnameOfVaccineThree] = useState('');
    const [doseOne, setdoseOne] = useState('1st');
    const [doseTwo, setdoseTwo] = useState('Additional');
    const [doseThree, setdoseThree] = useState('Additional');
    const [vaccinationDateOne, setvaccinationDateOne] = useState("");
    const [vaccinationDateTwo, setvaccinationDateTwo] = useState('');
    const [vaccinationDateThree, setvaccinationDateThree] = useState('');
    const [batchNoOne, setbatchNoOne] = useState('211C21A');
    const [batchNoTwo, setbatchNoTwo] = useState('210H21A');
    const [batchNoThree, setbatchNoThree] = useState('');
    const [profile, setProfile] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('fullName', fullName);
        formData.append('address', address);
        formData.append('nationality', nationality);
        formData.append('dob', dob);
        formData.append('passportNo', passportNo);
        formData.append('registrationNo', registrationNo);
        formData.append('nameOfVaccineOne', nameOfVaccineOne);
        formData.append('nameOfVaccineTwo', nameOfVaccineTwo);
        formData.append('nameOfVaccineThree', nameOfVaccineThree);
        formData.append('doseOne', doseOne);
        formData.append('doseTwo', doseTwo);
        formData.append('doseThree', doseThree);
        formData.append('vaccinationDateOne', vaccinationDateOne);
        formData.append('vaccinationDateTwo', vaccinationDateTwo);
        formData.append('vaccinationDateThree', vaccinationDateThree);
        formData.append('batchNoOne', batchNoOne);
        formData.append('batchNoTwo', batchNoTwo);
        formData.append('batchNoThree', batchNoThree);
        formData.append('profile', profile);

        try {
            const response = await axios.post(`${baseUri}/api/vaccinations`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            alert("Data Upload Success")
            window.location.assign(`${frontUri}/vaccination_detail/${response.data.id}`)

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='vaccineForm'>
            <h2>Add Record</h2>
            <a href={"/mnnmnmnmnmnmnngfgfgnmngffgfgfnmnnnn"}>Go Back</a>
            <form onSubmit={handleSubmit} className='formContainer'>
                <label>Full Name:</label>
                <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                <br />
                <label>Address:</label>
                <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                <br />

                <label>Nationality:</label>
                <input type="text" value={nationality} onChange={(e) => setNationality(e.target.value)} />
                <br />
                <label>Date of Birth:</label>
                <input type="date" value={dob} onChange={(e) => setdob(e.target.value)} />
                <br />
                <label>Passport No:</label>
                <input type="text" value={passportNo} onChange={(e) => setpassportNo(e.target.value)} />
                <br />
                <label>Registration ID:</label>
                <input type="text" value={registrationNo} onChange={(e) => setregistrationNo(e.target.value)} />
                <br />
                <label>Vaccine 1:</label>
                <select value={nameOfVaccineOne} onChange={(e) => setnameOfVaccineOne(e.target.value)} style={{ padding: "10px" }}>
                    <option>Janssen (Johnson & Johnson)</option>
                    <option>Verocell</option>
                    <option>Pfizer</option>
                    <option>Astrazeneca</option>
                </select>
                <br />
                <label>Vaccine 2:</label>
                <select value={nameOfVaccineTwo} onChange={(e) => setnameOfVaccineTwo(e.target.value)} style={{ padding: "10px" }}>
                    <option>Janssen (Johnson & Johnson)</option>
                    <option>Verocell</option>
                    <option>Pfizer</option>
                    <option>Astrazeneca</option>
                </select>
                <br />
                <label>Vaccine 3:</label>

                <input type="text" value={nameOfVaccineThree} onChange={(e) => setnameOfVaccineThree(e.target.value)} />
                <br />
                <label>Dose 1:</label>
                <select value={doseOne} onChange={(e) => setdoseOne(e.target.value)} style={{ padding: "10px" }}>
                    <option>1st</option>
                    <option>2nd</option>
                    <option>Additional</option>
                </select>
                <br />
                <label>Dose 2:</label>
                <select value={doseTwo} onChange={(e) => setdoseTwo(e.target.value)} style={{ padding: "10px" }}>
                    <option>2nd</option>
                    <option>1st</option>
                    <option>Additional</option>
                </select>
                <br />
                <label>Dose 3:</label>
                <select value={doseThree} onChange={(e) => setdoseThree(e.target.value)} style={{ padding: "10px" }}>
                    <option>Additional</option>
                    <option>2nd</option>
                    <option>1st</option>
                </select>
                <br />
                <label>Vaccine Date 1:</label>
                <input type="date" value={vaccinationDateOne} onChange={(e) => setvaccinationDateOne(e.target.value)} />
                <br />
                <label>Vaccine Date 2:</label>
                <input type="date" value={vaccinationDateTwo} onChange={(e) => setvaccinationDateTwo(e.target.value)} />
                <br />
                <label>Vaccine Date 3:</label>
                <input type="date" value={vaccinationDateThree} onChange={(e) => setvaccinationDateThree(e.target.value)} />
                <br />
                <label>Batch No 1:</label>
                <input type="text" value={batchNoOne} onChange={(e) => setbatchNoOne(e.target.value)} />
                <br />
                <label>Batch No 2:</label>
                <input type="text" value={batchNoTwo} onChange={(e) => setbatchNoTwo(e.target.value)} />
                <br />
                <label>Batch No 3:</label>
                <input type="text" value={batchNoThree} onChange={(e) => setbatchNoThree(e.target.value)} />
                <br />
                <label>Profile Image:</label>
                <input type="file" onChange={(e) => setProfile(e.target.files[0])} />
                <br />
                <button className='addDetails' type="submit">Submit</button>
            </form>
        </div>
    );
}

export default VaccinationForm;
